import React, { useState, useRef } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import styled from 'styled-components'
import {
  SignaturePreview,
  MemberDetails,
  LogoDisplay,
  FacilityDetails,
  SIGNATURE_LOGO_OPTIONS
} from '../../components/Signature'
import Layout from '../../components/layout'
import { withAuthProvider } from '../../lib/auth'

const Instructions = styled.ol`
  padding: 0 0 0 1.25rem;
  margin-top: 1rem;

  li {
    margin-bottom: 1rem;
  }
`

const SignaturePage = ({ me }) => {
  const { firstName, lastName, phoneNumber, primaryEmail } = me
  const [form, setForm] = useState({
    name: firstName && lastName ? `${firstName} ${lastName}` : 'Your Name',
    position: 'Your Title',
    email: primaryEmail || 'youremail@pgahq.com',
    phone: phoneNumber || '555.123.4567',
    memberDesignation: "",
    additionalCertifications: "",
    pgaSection: 'Your PGA Section',
    logo: 'PGA of America',
    facility: '',
    address: '',
    location: ''
  })
  const signatureRef = useRef()
  const { logo, ...userInput } = form

  const {
    name,
    position,
    memberDesignation,
    additionalCertifications,
    pgaSection,
    email,
    phone,
    facility,
    address,
    location
  } = userInput

  const handleChange = event => {
    const { name, value } = event.target
    setForm(prevForm => ({ ...prevForm, [name]: value }))
  }
  
  const handleSelectCertifications = e => {
    const check = e.target.checked;
    const checkedCertification = e.target.value;
  
    setForm(prevForm => ({ ...prevForm, 
      additionalCertifications:  check ? [...prevForm.additionalCertifications, checkedCertification] : prevForm.additionalCertifications.filter(certification => certification !== checkedCertification)}));
  
  };

  const handleCopyToClipboard = htmlRef => {
    if (htmlRef.current) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(htmlRef.current)
      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeAllRanges()
    }
  }

  return (
    <Layout>
      <Container className='mt-5 mt-lg-2 mb-5'>
        <Row className='justify-content-center'>
          <Col lg='8'>
            <h2>Generate a Custom Email Signature</h2>
            <Instructions>
              <li>
                Change the text in the fields as needed to update the signature
                displayed to the right of the form (or below the form on mobile
                devices).
              </li>
              <li>Select a logo from the dropdown menu.</li>
              <li>
                If desired, add facility information using the facility name,
                address and location fields.
              </li>
              <li>
                Click the <strong>Copy Signature</strong> button to copy the
                signature to your clipboard.
              </li>
              <li>
                Paste the signature into your email client settings and save for
                future use.
              </li>
            </Instructions>
          </Col>
        </Row>
        <Row className='justify-content-center mt-5'>
          <Col lg='6'>
            <Form>
              <FormGroup>
                <Label for='name'>Name</Label>
                <Input
                  type='text'
                  name='name'
                  id='name'
                  value={name}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for='position'>Title</Label>
                <Input
                  type='text'
                  name='position'
                  id='position'
                  value={position}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for='memberDesignation'>
                  Member Designation (this field can be left blank or customized)
                </Label>
                <Input
                  type='select'
                  name='memberDesignation'
                  id='memberDesignation'
                  value={memberDesignation}
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="PGA of America Member">PGA of America Member</option>
                  <option value="Specialized Professional">Specialized Professional</option>
                  <option value="Certified Professional">Certified Professional</option>
                  <option value="Master Professional">Master Professional</option>
                </Input>
              </FormGroup>
              <Label for='additionalCertifications'>
                Additional PGA Certifications (this field can be left blank or customized)
              </Label>
              <input type="hidden" name="additionalCertifications" />
              <div className='mb-3'>
                <FormGroup
                  check
                  inline
                >
                  <Input 
                    type="checkbox"
                    id='PGAHOPECertified'
                    value="PGA HOPE Certified"
                    onChange={e => handleSelectCertifications(e)}
                  />
                  <Label for="PGAHOPECertified" check>
                    PGA HOPE Certified
                  </Label>
                </FormGroup>
                <FormGroup
                  check
                  inline
                >
                  <Input 
                    id='ADMCertified'
                    type="checkbox"
                    value="ADM Certified"
                    onChange={e => handleSelectCertifications(e)}
                  />
                  <Label for="ADMCertified" check>
                    ADM Certified
                  </Label>
                </FormGroup>
              </div>
              <FormGroup>
                <Label for='pgaSection'>
                  PGA Section (Optional: leave blank if you do not want to
                  include)
                </Label>
                <Input
                  type='text'
                  name='pgaSection'
                  id='pgaSection'
                  value={pgaSection}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for='email'>Email</Label>
                <Input
                  type='email'
                  name='email'
                  id='email'
                  value={email}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for='phone'>Phone</Label>
                <Input
                  type='tel'
                  name='phone'
                  id='phone'
                  value={phone}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for='logo'>Logo</Label>
                <Input
                  type='select'
                  name='logo'
                  id='logo'
                  value={logo}
                  onChange={handleChange}
                >
                  {Object.keys(SIGNATURE_LOGO_OPTIONS).map((logo, i) => (
                    <option key={i}>{logo}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for='facility'>Facility Name</Label>
                <Input
                  type='text'
                  name='facility'
                  id='facility'
                  value={facility}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for='address'>Facility Street Address</Label>
                <Input
                  type='text'
                  name='address'
                  id='address'
                  value={address}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for='location'>
                  Facility Location (city, state zipcode)
                </Label>
                <Input
                  type='text'
                  name='location'
                  id='location'
                  value={location}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col lg='5' className='offset-lg-1 mt-5 mt-lg-0'>
            <div ref={signatureRef}>
              <SignaturePreview>
                <MemberDetails userInput={userInput} />
                <LogoDisplay logo={logo} />
                {facility && <FacilityDetails userInput={userInput} />}
              </SignaturePreview>
            </div>
            <Button
              className='btn btn-secondary text-white mt-5'
              onClick={() => handleCopyToClipboard(signatureRef)}
            >
              Copy Signature
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default withAuthProvider(SignaturePage)
