import React from 'react'

export const SIGNATURE_LOGO_OPTIONS = {
  'PGA of America': {
    src: 'https://pgahq.github.io/email-sigs/logos/pgaa-wltg.png',
    linkUrl: 'https://www.pga.com/'
  },
  'PGA of America Member': {
    src: 'https://pgahq.github.io/email-sigs/logos/pgaa_mbr.jpeg',
    linkUrl: 'https://www.pga.com/'
  },
  'PGA of America Master Professional': {
    src: 'https://pgahq.github.io/email-sigs/logos/pgaa_mstr.jpeg',
    linkUrl: 'https://www.pga.com/'
  }
}

export const SignaturePreview = ({ children }) => {
  return (
    <table
      width='300'
      cellSpacing='0'
      cellPadding='0'
      border='0'
      style={{
        padding: '16px 0',
        fontSize: '13px',
        fontWeight: 500,
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
      }}
    >
      <tbody>{children}</tbody>
    </table>
  )
}

export const MemberDetails = ({ userInput }) => {
  const { name, email, phone, position, pgaSection, memberDesignation, additionalCertifications  } = userInput
  return (
    <tr>
      <td
        style={{
          padding: 0,
          verticalAlign: 'top',
          textAlign: 'left',
          color: '#00205b',
          fontFamily: 'Arial'
        }}
      >
        <span
          style={{
            textTransform: 'uppercase',
            paddingTop: 10,
            color: '#00205b',
            fontSize: 14,
            fontWeight: 'bold'
          }}
        >
          {name}
        </span>

        <br />
        <span
          style={{ marginBottom: 12, color: '#58595B', fontWeight: 'bold' }}
        >
          {position}
        </span>
        {memberDesignation && (
          <>
            <br />
            <span
              style={{ marginBottom: 12, color: '#58595B', fontWeight: 'bold' }}
            >
              {memberDesignation}
            </span>
          </>
        )}
        {additionalCertifications?.length > 0 && (
          <>
            <br />
            <span
              style={{ marginBottom: 12, color: '#58595B', fontWeight: 'bold' }}
            >
              {additionalCertifications.join(', ')}
            </span>
          </>
        )}
        {pgaSection && (
          <>
            <br />
            <span
              style={{ marginBottom: 12, color: '#58595B', fontWeight: 'bold' }}
            >
              {pgaSection}
            </span>
          </>
        )}
        <br />
        <br />
        <span style={{ marginBottom: 16, color: '#58595B' }}>
          <a href={`mailto:${email}`}>{email}</a>
        </span>
        <br />
        <span style={{ marginBottom: 16, color: '#58595B' }}>{phone}</span>
        <br />
        <br />
      </td>
    </tr>
  )
}

export const LogoDisplay = ({ logo }) => {
  const { src, linkUrl } = SIGNATURE_LOGO_OPTIONS[logo]

  return (
    <table>
      <tbody>
        <tr>
          <td style={{ verticalAlign: 'top', padding: 0 }}>
            <a href={linkUrl} data-external='true'>
              <img
                height='67'
                style={{ height: 67, border: 'none' }}
                src={src}
              />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const FacilityDetails = ({ userInput }) => {
  const { facility, address, location } = userInput

  return (
    <>
      <table style={{ marginTop: 20, marginBottom: 15 }}>
        <tbody>
          <tr width='80'>
            <td
              width='80'
              style={{
                width: '80px',
                padding: 0,
                borderTop: 'solid #d4d4d4 1px'
              }}
            ></td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td style={{ padding: 0, fontFamily: 'Arial', fontSize: 12 }}>
              <span
                style={{
                  paddingTop: 12,
                  marginBottom: 12,
                  color: '#58595B',
                  fontWeight: 'bold'
                }}
              >
                {facility}
              </span>
              <br />
              <span style={{ color: '#58595B' }}>
                {address}
                <br />
                {location}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
